<template>
  <div class="contain">
    <div class="form-header-btn">
      <div class="header-title">出勤列表</div>
      <div>
        <el-button
          type="primary"
          class="sheet-btn"
          @click="downLoadFile"
          v-if="isExport"
        >
          导出
        </el-button>
      </div>
    </div>

    <!-- 出勤-园校 -->
    <el-form :model="form" onsubmit="return false" :inline="true" class="search-form">
      <el-form-item>
        <el-select v-model="form.classId" clearable placeholder="请选择班级">
          <el-option
            v-for="item in moduleOptions"
            :key="item.label"
            :label="item.name"
            :value="item.label"
          >
          </el-option>
        </el-select>
      </el-form-item>

      <el-form-item>
        <el-date-picker
          type="month"
          placeholder="请选择日期"
          v-model="form.attendanceDate"
          value-format="yyyy-MM"
          style="width: 100%"
          :picker-options="pickerOptions"
          :clearable="false"
        ></el-date-picker>
      </el-form-item>

      <el-form-item>
        <el-button type="primary" icon="el-icon-search" @click="searchFun">
          查询
        </el-button>
      </el-form-item>

      <el-form-item>
        <el-button icon="el-icon-refresh-left" @click="resetFun">
          重置
        </el-button>
      </el-form-item>
    </el-form>
    <!-- 表格 -->
    <el-table
      :data="tableData"
      :header-cell-style="{
        background: '#F1F4F9',
        color: '#5E6D9B',
        fontWeight: '400',
        'text-align':'center'
      }"
      :summary-method="getSummaries"
      show-summary
      v-loading="tableLoading"
      @sort-change="sortChange"
      ref="attendanceTable"
    >
      <el-table-column
        prop="className"
        label="班级"
        min-width="180"
        fixed="left"
        align="center"
      ></el-table-column>
      <el-table-column prop="classNumber" label="班级人数" min-width="80" align="center">
        <template slot-scope="scope">
          <!-- 编辑input框 -->
          <el-input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"
            @mousewheel.native.prevent
            placeholder="请输入"
            v-show="scope.row.show"
            v-model="scope.row.classNumber"
            :row-index="scope.$index"
            :maxlength="10"
            oninput="if(value.length > 9) value = value.slice(0,9)"
          ></el-input>
          <span v-show="!scope.row.show">
            {{ scope.row.classNumber }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="attendanceDays" label="出勤天数" min-width="80" align="center">
        <template slot-scope="scope">
          <!-- 编辑input框 -->
          <el-input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"
            @mousewheel.native.prevent
            placeholder="请输入"
            v-show="scope.row.show"
            v-model="scope.row.attendanceDays"
            :row-index="scope.$index"
            oninput="if(value.length > 9) value = value.slice(0,9)"
          ></el-input>
          <span v-show="!scope.row.show">
            {{ scope.row.attendanceDays }}
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="inAttendance" label="转入出勤人次" min-width="120" align="center">
        <template slot-scope="scope">
          <!-- 编辑input框 -->
          <el-input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"
            @mousewheel.native.prevent
            placeholder="请输入"
            v-show="scope.row.show"
            v-model="scope.row.inAttendance"
            :row-index="scope.$index"
            oninput="if(value.length > 9) value = value.slice(0,9)"
          ></el-input>
          <span v-show="!scope.row.show">
            {{ scope.row.inAttendance }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="outAttendance"
        label="转出缺勤人次"
        min-width="120"
        align="center"
      >
        <template slot-scope="scope">
          <!-- 编辑input框 -->
          <el-input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"
            @mousewheel.native.prevent
            placeholder="请输入"
            v-show="scope.row.show"
            v-model="scope.row.outAttendance"
            :row-index="scope.$index"
            oninput="if(value.length > 9) value = value.slice(0,9)"
          ></el-input>
          <span v-show="!scope.row.show">
            {{ scope.row.outAttendance }}
          </span>
        </template>
      </el-table-column>
      <el-table-column
        prop="attendanceNumber"
        label="应出勤人次"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="attendanceRealNumber"
        label="实际出勤人次"
        min-width="120"
        align="center"
      ></el-table-column>
      <el-table-column
        prop="attendanceRate"
        label="出勤率"
        min-width="100"
        align="center"
        sortable="custom"
      >
        <template slot-scope="{ row }">
          <span v-if="row.attendanceRate">
            {{ row.attendanceRate + "%" }}
          </span>
        </template>
      </el-table-column>
      <!-- <template> -->
      <el-table-column
        v-for="(item, index) in attendanceDetailsList"
        :key="index"
        :label="item.label"
        :prop="item.prop"
        min-width="80"
        align="center"
      >
        <template slot-scope="scope">
          <!-- 编辑input框 -->
          <el-input
            type="number"
            onKeypress="return (/[\d]/.test(String.fromCharCode(event.keyCode || event.which))) || event.which === 8"
            @mousewheel.native.prevent
            placeholder="请输入"
            v-show="scope.row.show"
            v-model="scope.row[`day${index + 1}`]"
            :row-index="scope.$index"
            oninput="if(value.length > 9) value = value.slice(0,9)"
          ></el-input>
          <!-- 默认出勤数据 -->
          <span v-show="!scope.row.show">
            {{ scope.row[item.prop] }}
          </span>
        </template>
      </el-table-column>
      <!-- </template> -->

      <el-table-column fixed="right" label="操作" width="100" align="center">
        <template slot-scope="scope">
          <!-- 编辑 -->
          <el-button
            size="medium"
            type="text"
            v-if="!scope.row.show && isEdit"
            @click="handleEdit(scope.$index, scope.row)"
            >编辑</el-button
          >
          <!-- 保存 -->
          <el-button
            size="medium"
            type="text"
            v-if="scope.row.show"
            @click="handleSave(scope.$index, scope.row)"
            >保存</el-button
          >
          <!-- 取消 -->
          <el-button
            size="medium"
            type="text"
            v-if="scope.row.show"
            @click="handleCancel(scope.$index, scope.row)"
            >取消</el-button
          >
        </template>
      </el-table-column>
    </el-table>
  </div>
</template>

<script>
import downloadFile from "@/utils/downloadFile";
import { getPermissionButton } from "@/utils/getUrlPermissionButtons";
import { mapGetters, mapState } from "vuex";
export default {
  data() {
    return {
      form: {
        classId: "",
        attendanceDate: "",
      }, //表单
      moduleOptions: [], // 所属模块下拉
      tableData: [], //表格数据
      tableLoading: false, // 表格loading
      attendanceDetailsList: [], //月天数
      show: false, //是否显示
      formExport: {
        classId: "",
        attendanceDate: "",
      },
      permissionButtonList: [], // 权限按钮list
      isExport: false,
      isEdit: false,
      pickerOptions: {
        disabledDate(times) {
          return times.getTime() > Date.now();
        },
      },
      nowYearMonth: "", //初始化时间
      resetDownDate: 0,
      advenSum: "",
    };
  },

  computed: {
    ...mapState({
      menuList: (state) => state.common.menuList,
      userInfo: (state) => state.userInfo,
    }),
  },

  watch: {
    permissionButtonList: {
      handler(val) {
        if (val) {
          this.isExport = val.indexOf("y_attendance:export") != -1;
          this.isEdit = val.indexOf("y_attendance:edit") != -1;
        }
      },
      deep: true,
    },
    menuList: {
      handler(val) {
        if (val) {
          this.getPermissionButton(this.$route.path, (data) => {
            this.permissionButtonList = data;
          });
        }
      },
      deep: true,
    },
  },

  created() {
    // menulist一直在时这个方法不可缺少
    this.getPermissionButton(this.$route.path, (data) => {
      this.permissionButtonList = data;
    });
  },

  mounted() {
    // this.tableLoading = true;
    this.getDateNow();
    // this.init();
    this.getClassInfoList();
    this.getRateByMonthSchSum();
  },
  activated(){
    this.tableLoading = true;
    this.init();
  },


  methods: {
    //合计
    getRateByMonthSchSum() {
      let params = {
        classId: this.form.classId,
        attendanceDate: this.form.attendanceDate,
      };
      this.$api.getRateByMonthSchSum(params).then((res) => {
        if (res.data.code == "0") {
          this.tableLoading = false;
          this.advenSum = res.data.data;
        } else {
          this.tableLoading = false;
          this.$message.error(res.data.msg);
        }
      });
    },

    //排序
    sortChange(column) {
      // console.log(column);
      console.log(column.prop, column.order);
      // this.columnOrder = column.order;
      if (column.order == "descending") {
        this.init(column.prop, "2");
      } else if (column.order == "ascending") {
        this.init(column.prop, "1");
      }
    },

    getPermissionButton,

    //初始化获取当前月天数
    getDateNow(dateparams) {
      var date = dateparams ? new Date(dateparams) : new Date();
      var year = date.getFullYear();
      var month = date.getMonth() + 1;

      var dateReset = new Date();
      var yearReset = dateReset.getFullYear();
      var monthReset = dateReset.getMonth() + 1;

      this.nowYearMonth = `${yearReset}-${
        monthReset > 9 ? monthReset : "0" + monthReset
      }`;

      if (!dateparams) {
        this.form.attendanceDate = `${year}-${month > 9 ? month : "0" + month}`;
      }
      var d = new Date(year, month, 0);
      var dayCounts = d.getDate();
      var arr = [];
      for (var i = 0; i <= dayCounts - 1; i++) {
        var obj = {};
        obj.label = `${i + 1}日`;
        obj.prop = `day${i + 1}`;
        arr.push(obj);
      }
      this.attendanceDetailsList = arr;
    },

    /** 初始化 */
    init(sortName, order) {
      let params = {
        classId: this.form.classId,
        attendanceDate: this.form.attendanceDate,
      };

      if (sortName === "attendanceRate" && order == "2") {
        params.attendanceRateOrder = "2";
      } else if (sortName === "attendanceRate" && order == "1") {
        params.attendanceRateOrder = "1";
      }

      this.$api.getAttendanceByMonth(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          res.data.data.map((item) => {
            item.show = false;
            if (
              item.attendanceDetailsList &&
              item.attendanceDetailsList.length > 0
            ) {
              item.attendanceDetailsList.map((child) => {
                item[`day${child.attendanceTime}`] = child.attendanceNumber;
              });
            }
          });
          this.tableData = res.data.data;
          this.$nextTick(() => {
            this.$refs.attendanceTable.doLayout()
          })
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //查询班级
    getClassInfoList() {
      let params = {};
      this.$api.getClassInfo(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.moduleOptions = res.data.data;
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //表尾合计
    getSummaries(param) {
      const { columns, data } = param;
      const sums = [];

      columns.forEach((column, index) => {
        // console.log(column.property, index, "hejijiiiiijijijij");
        if (index === 0) {
          sums[index] = "合计";
          return;
        }

        const values = data.map((item) => Number(item[column.property]));

        // console.log(values, "shishenm");
        // if (column.property === "attendanceNumber") {
        // const resvue = values.includes(1);
        // sums[index] = resvue == true ? "是" : "否";
        // return;
        // }

        // if (column.property === "executionRatio") {
        //   const resvue = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);

        //   const adven = (resvue / values.length).toFixed(2);
        //   sums[index] = adven + "%";
        //   return;
        // }

        // if (index === 7) {
        //   // sums[index] = "合计";
        //   const resvue = values.reduce((prev, curr) => {
        //     const value = Number(curr);
        //     if (!isNaN(value)) {
        //       return prev + curr;
        //     } else {
        //       return prev;
        //     }
        //   }, 0);

        //   console.log("shifou为0", resvue);

        //   let validLength = 0;
        //   values.map((item, index) => {
        //     if (item > 0) {
        //       return validLength++;
        //     }
        //   });

        //   const adven =
        //     validLength > 0 ? (resvue / validLength).toFixed(2) : "0.00";
        //   sums[index] = adven + "%";
        //   return;
        // }

        if (index === 7) {
          // this.sums = this.advenSum + "%";
          sums[index] = this.advenSum + "%";
          return;
        }

        if (!values.every((value) => isNaN(value))) {
          sums[index] = values.reduce((prev, curr) => {
            const value = Number(curr);
            if (!isNaN(value)) {
              return prev + curr;
            } else {
              return prev;
            }
          }, 0);
        }
      });
      this.sums = sums;
      return sums;
    },

    //编辑
    handleEdit(index, row) {
      row.show = true;
    },

    //保存出勤-btn
    handleSave(index, row) {
      row.show = false;
      let attendanceDetailsList = [];
      for (let key in row) {
        if (key.includes("day")) {
          var obj = {};
          obj.attendanceTime = key.substr(3, 5);
          obj.attendanceNumber = row[key];
          attendanceDetailsList.push(obj);
        }
      }

      let params = {
        id: row.id,
        orgId: row.orgId, //公司id
        parkId: row.parkId, //园校id
        classId: row.classId, //班级id
        attendanceDate: this.form.attendanceDate, //年月
        classNumber: row.classNumber, //班级人数
        attendanceDays: row.attendanceDays, //出勤人数
        inAttendance: row.inAttendance, //转入出勤人次
        outAttendance: row.outAttendance, //转出缺勤人次
        attendanceDetailsList,
      };

      //保存出勤-接口
      this.$api.getAttendanceSave(params).then((res) => {
        this.tableLoading = false;
        if (res.data.code == "0") {
          this.$message.success(res.data.msg);
          this.init();
        } else {
          this.$message.error(res.data.msg);
        }
      });
    },

    //取消
    handleCancel(index, row) {
      row.show = false;
      this.init();
    },

    //导出
    downLoadFile() {
      //下载文件
      let url = "/mng/attendance/attendance/export";
	  let userInfo = sessionStorage.getItem("userInfo")
	    ? JSON.parse(sessionStorage.getItem("userInfo"))
	    : {};
      let data = {
		orgId: userInfo.schoolList[0].companyLabel,
		parkId: userInfo.schoolList[0].schoolLabel,  
        classId: this.form.classId,
        attendanceDate:
          this.resetDownDate == 1
            ? this.nowYearMonth
            : this.form.attendanceDate,
		downFlag:"3"
      };

      let type = "get";

      this.tableLoading = true;
      downloadFile({ url, data, type }).then(() => {
        this.tableLoading = false;
      });

      this.resetDownDate = 0;
    },

    // 查询
    searchFun() {
      this.tableLoading = true;
      this.getDateNow(this.form.attendanceDate);
      this.init();
      this.getRateByMonthSchSum();
      this.formExport = {
        classId: this.form.classId,
        attendanceDate: this.form.attendanceDate,
      };
    },

    // 重置
    resetFun() {
      this.form = {
        classId: "",
        attendanceDate: this.nowYearMonth,
      };
      this.resetDownDate = 1;
      this.init();
    },
  },
};
</script>
<style lang="scss" scoped>
@import "@/assets/css/select_list.scss";

.contain {
  padding: 26px 30px 30px 30px;

  /deep/ .el-table__body-wrapper {
    // z-index: 2;
  }

  /* element样式重置 start */
  /* 去掉input尾部上下小箭头 start */
  /deep/ input::-webkit-outer-spin-button,
  /deep/ input::-webkit-inner-spin-button {
    -webkit-appearance: none !important;
    -moz-appearance: none !important;
    -o-appearance: none !important;
    -ms-appearance: none !important;
    appearance: none !important;
    margin: 0;
  }
  /deep/ input[type="number"] {
    -webkit-appearance: textfield;
    -moz-appearance: textfield;
    -o-appearance: textfield;
    -ms-appearance: textfield;
    appearance: textfield;
  }
  /* 去掉input尾部上下小箭头 end */

  .form-header-btn {
    display: flex;
    margin-bottom: 16px;

    .sheet-btn {
      background: #003685;
      box-shadow: 0px 3px 10px rgba(52, 85, 236, 0.4);
      opacity: 1;
      border-radius: 4px;
      font-size: 14px;
      color: #ffffff;
    }

    /deep/.el-button + .el-button {
      margin-left: 20px;
    }
  }

  /deep/.el-table {
    // margin-top: 14px;
  }

  /deep/.el-input__inner {
    // text-align: center;
  }

  // /deep/.el-table th > .cell {
  //   padding-left: 14px;
  // }

  //   .el-table .cell {
  //     box-sizing: border-box;
  //     overflow: hidden;
  //     text-overflow: ellipsis;
  //     white-space: normal;
  //     word-break: break-all;
  //     line-height: 23px;
  //     padding-left: 12px;
  //     padding-right: 12px;

  // }
}
</style>
